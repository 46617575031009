@layer panda_reset, panda_base, panda_tokens, panda_recipes, panda_utilities;

html {
	scroll-behavior: smooth;
}

html,
body {
	height: 100% !important;
}

body {
	margin: 0;
}

#root {
	height: 100%;
	width: 100vw;
	display: flex;
	flex-direction: column;
}

.p-dialog-mask.p-component-overlay {
	z-index: 1000 !important;
}
