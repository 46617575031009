@layer panda_reset, panda_base, panda_tokens, panda_recipes, panda_utilities;

@layer panda_reset{
  html,:host {
    --font-fallback: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -moz-tab-size: 4;
    tab-size: 4;
    -webkit-tap-highlight-color: transparent;
    line-height: 1.5;
    font-family: var(--global-font-body, var(--font-fallback));
}

  *,::before,::after,::backdrop,::file-selector-button {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    border-width: 0px;
    border-style: solid;
    border-color: var(--global-color-border, currentColor);
}

  hr {
    height: 0px;
    color: inherit;
    border-top-width: 1px;
}

  body {
    height: 100%;
    line-height: inherit;
}

  img {
    border-style: none;
}

  img,svg,video,canvas,audio,iframe,embed,object {
    display: block;
    vertical-align: middle;
}

  img,video {
    max-width: 100%;
    height: auto;
}

  h1,h2,h3,h4,h5,h6 {
    text-wrap: balance;
    font-size: inherit;
    font-weight: inherit;
}

  p,h1,h2,h3,h4,h5,h6 {
    overflow-wrap: break-word;
}

  ol,ul,menu {
    list-style: none;
}

  button,input:where([type='button'], [type='reset'], [type='submit']),::file-selector-button {
    appearance: button;
    -webkit-appearance: button;
}

  button,input,optgroup,select,textarea,::file-selector-button {
    font: inherit;
    font-feature-settings: inherit;
    font-variation-settings: inherit;
    letter-spacing: inherit;
    color: inherit;
    background: transparent;
}

  ::placeholder {
    opacity: 1;
    --placeholder-fallback: color-mix(in srgb, currentColor 50%, transparent);
    color: var(--global-color-placeholder, var(--placeholder-fallback));
}

  textarea {
    resize: vertical;
}

  table {
    text-indent: 0px;
    border-collapse: collapse;
    border-color: inherit;
}

  summary {
    display: list-item;
}

  small {
    font-size: 80%;
}

  sub,sup {
    position: relative;
    vertical-align: baseline;
    font-size: 75%;
    line-height: 0;
}

  sub {
    bottom: -0.25em;
}

  sup {
    top: -0.5em;
}

  dialog {
    padding: 0px;
}

  a {
    color: inherit;
    text-decoration: inherit;
}

  abbr:where([title]) {
    text-decoration: underline dotted;
}

  b,strong {
    font-weight: bolder;
}

  code,kbd,samp,pre {
    --font-mono-fallback: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New';
    font-feature-settings: normal;
    font-variation-settings: normal;
    font-family: var(--global-font-mono, var(--font-mono-fallback));
    font-size: 1em;
}

  progress {
    vertical-align: baseline;
}

  ::-webkit-search-decoration,::-webkit-search-cancel-button {
    -webkit-appearance: none;
}

  ::-webkit-inner-spin-button,::-webkit-outer-spin-button {
    height: auto;
}

  :-moz-ui-invalid {
    box-shadow: none;
}

  :-moz-focusring {
    outline: auto;
}

  [hidden] {
    display: none !important;
}
}

@layer panda_base{
  :root {
    --made-with-panda: '🐼';
}

  *,::before,::after,::backdrop {
    --blur: /*-*/ /*-*/;
    --brightness: /*-*/ /*-*/;
    --contrast: /*-*/ /*-*/;
    --grayscale: /*-*/ /*-*/;
    --hue-rotate: /*-*/ /*-*/;
    --invert: /*-*/ /*-*/;
    --saturate: /*-*/ /*-*/;
    --sepia: /*-*/ /*-*/;
    --drop-shadow: /*-*/ /*-*/;
    --backdrop-blur: /*-*/ /*-*/;
    --backdrop-brightness: /*-*/ /*-*/;
    --backdrop-contrast: /*-*/ /*-*/;
    --backdrop-grayscale: /*-*/ /*-*/;
    --backdrop-hue-rotate: /*-*/ /*-*/;
    --backdrop-invert: /*-*/ /*-*/;
    --backdrop-opacity: /*-*/ /*-*/;
    --backdrop-saturate: /*-*/ /*-*/;
    --backdrop-sepia: /*-*/ /*-*/;
    --gradient-from-position: /*-*/ /*-*/;
    --gradient-to-position: /*-*/ /*-*/;
    --gradient-via-position: /*-*/ /*-*/;
    --scroll-snap-strictness: proximity;
    --border-spacing-x: 0;
    --border-spacing-y: 0;
    --translate-x: 0;
    --translate-y: 0;
    --rotate: 0;
    --rotate-x: 0;
    --rotate-y: 0;
    --skew-x: 0;
    --skew-y: 0;
    --scale-x: 1;
    --scale-y: 1;
}
}

@layer panda_tokens{
  :where(:root, :host) {
    --colors-primary-20: rgb(207, 209, 210);
    --colors-primary-40: rgb(159, 163, 166);
    --colors-primary-80: rgb(64, 70, 77);
    --colors-primary-100: rgb(16, 24, 32);
    --colors-secondary-20: rgb(212, 250, 235);
    --colors-secondary-40: rgb(169, 245, 215);
    --colors-secondary-80: rgb(82, 234, 175);
    --colors-secondary-100: rgb(39, 229, 155);
    --colors-secondary-120: rgb(7, 158, 131);
    --colors-on-primary: rgb(244, 248, 249);
    --colors-on-secondary: rgb(16, 24, 32);
    --colors-shade-0: rgb(255, 255, 255);
    --colors-shade-5: rgb(243, 243, 244);
    --colors-shade-10: rgb(231, 232, 233);
    --colors-shade-20: rgb(207, 209, 210);
    --colors-shade-40: rgb(159, 163, 166);
    --colors-shade-60: rgb(112, 116, 121);
    --colors-shade-80: rgb(64, 70, 77);
    --colors-shade-100: rgb(16, 24, 32);
    --colors-success-0: rgb(237, 249, 238);
    --colors-success-20: rgb(209, 234, 210);
    --colors-success-100: rgb(24, 152, 29);
    --colors-info-0: rgb(238, 246, 254);
    --colors-info-20: rgb(212, 234, 255);
    --colors-info-100: rgb(39, 149, 254);
    --colors-warning-0: rgb(255, 252, 239);
    --colors-warning-20: rgb(255, 246, 214);
    --colors-warning-100: rgb(224, 168, 39);
    --colors-error-0: rgb(255, 242, 237);
    --colors-error-20: rgb(251, 221, 210);
    --colors-error-100: rgb(234, 86, 28);
    --colors-transaprent: rgba(0, 0, 0, 0);
    --spacing-0: 0rem;
    --spacing-2: 0.125rem;
    --spacing-4: 0.25rem;
    --spacing-8: 0.5rem;
    --spacing-10: 0.625rem;
    --spacing-12: 0.75rem;
    --spacing-16: 1rem;
    --spacing-20: 1.25rem;
    --spacing-24: 1.5rem;
    --spacing-32: 2rem;
    --spacing-40: 2.5rem;
    --spacing-64: 4rem;
    --spacing-80: 5rem;
    --spacing-96: 6rem;
    --radii-0: 0rem;
    --radii-4: 0.25rem;
    --radii-6: 0.375rem;
    --radii-8: 0.5rem;
    --radii-10: 0.625rem;
    --radii-12: 0.75rem;
    --radii-16: 1rem;
    --radii-20: 1.25rem;
    --radii-24: 1.5rem;
    --radii-100: 6.25rem;
    --radii-full: 9999rem;
    --fonts-default: Roboto, sans-serif;
    --font-weights-normal: 400;
    --font-weights-bold: 700;
    --font-weights-extra-bold: 900;
    --font-sizes-10: 0.625rem;
    --font-sizes-12: 0.75rem;
    --font-sizes-14: 0.875rem;
    --font-sizes-16: 1rem;
    --font-sizes-20: 1.25rem;
    --font-sizes-22: 1.375rem;
    --line-heights-10: 0.625rem;
    --line-heights-16: 1rem;
    --line-heights-18: 1.125rem;
    --line-heights-20: 1.25rem;
    --line-heights-22: 1.375;
    --line-heights-26: 1.625rem;
    --line-heights-28: 1.75rem;
    --breakpoints-md: 576px;
    --breakpoints-lg: 992px;
    --breakpoints-xl: 1200px;
    --breakpoints-2xl: 1400px;
    --sizes-breakpoint-md: 576px;
    --sizes-breakpoint-lg: 992px;
    --sizes-breakpoint-xl: 1200px;
    --sizes-breakpoint-2xl: 1400px;
}
}

@layer panda_utilities{
  @layer compositions{
    .textStyle_h1 {
      font-family: var(--fonts-default);
      font-size: var(--font-sizes-20);
      line-height: var(--line-heights-26);
      font-weight: var(--font-weights-bold);
}

    .textStyle_h2 {
      font-size: var(--font-sizes-16);
}

    .textStyle_h2,.textStyle_h3 {
      font-family: var(--fonts-default);
      line-height: var(--line-heights-20);
      font-weight: var(--font-weights-bold);
}

    .textStyle_h3 {
      font-size: var(--font-sizes-14);
}

    .textStyle_h4 {
      font-family: var(--fonts-default);
      line-height: var(--line-heights-16);
      font-size: var(--font-sizes-12);
      font-weight: var(--font-weights-bold);
}

    .textStyle_p1 {
      line-height: var(--line-heights-28);
      font-size: var(--font-sizes-16);
}

    .textStyle_p1,.textStyle_p2 {
      font-family: var(--fonts-default);
      font-weight: var(--font-weights-normal);
}

    .textStyle_p2,.textStyle_p2Anchor {
      line-height: var(--line-heights-22);
      font-size: var(--font-sizes-14);
}

    .textStyle_p2Anchor {
      font-family: var(--fonts-default);
      font-weight: var(--font-weights-normal);
      text-decoration-line: underline;
}

    .textStyle_p3,.textStyle_p3Anchor {
      font-family: var(--fonts-default);
      line-height: var(--line-heights-18);
      font-size: var(--font-sizes-12);
      font-weight: var(--font-weights-normal);
}

    .textStyle_p3Anchor {
      text-decoration-line: underline;
}

    .textStyle_l1 {
      line-height: var(--line-heights-10);
      font-size: var(--font-sizes-10);
}

    .textStyle_l1,.textStyle_b1 {
      font-family: var(--fonts-default);
      font-weight: var(--font-weights-normal);
}

    .textStyle_b1 {
      line-height: var(--line-heights-16);
      font-size: var(--font-sizes-14);
}

    @media screen and (min-width: 36rem) {
      .textStyle_h1 {
        line-height: var(--line-heights-28);
        font-size: var(--font-sizes-22);
}
}
}

  .d_flex {
    display: flex;
}

  .gap_8 {
    gap: var(--spacing-8);
}

  .\--thickness_1px {
    --thickness: 1px;
}

  .w_100\% {
    width: 100%;
}

  .my_16 {
    margin-block: var(--spacing-16);
}

  .gap_16 {
    gap: var(--spacing-16);
}

  .w_\[30rem\] {
    width: 30rem;
}

  .gap_2 {
    gap: var(--spacing-2);
}

  .d_grid {
    display: grid;
}

  .gap_10px {
    gap: 10px;
}

  .py_8 {
    padding-block: var(--spacing-8);
}

  .py_16 {
    padding-block: var(--spacing-16);
}

  .min-w_\[25rem\] {
    min-width: 25rem;
}

  .justify_space-around {
    justify-content: space-around;
}

  .flex_row {
    flex-direction: row;
}

  .border-block-end-width_var\(--thickness\) {
    border-block-end-width: var(--thickness);
}

  .mb_16 {
    margin-bottom: var(--spacing-16);
}

  .justify_space-between {
    justify-content: space-between;
}

  .grid-cols_\[1fr_1fr\] {
    grid-template-columns: 1fr 1fr;
}

  .flex_column {
    flex-direction: column;
}

  .items_center {
    align-items: center;
}

  .\[\&_\>_\:first-child\]\:flex_1 > :first-child {
    flex: 1 1 0%;
}
}